// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-calculators-emi-jsx": () => import("./../src/pages/calculators/emi.jsx" /* webpackChunkName: "component---src-pages-calculators-emi-jsx" */),
  "component---src-pages-calculators-incometax-jsx": () => import("./../src/pages/calculators/incometax.jsx" /* webpackChunkName: "component---src-pages-calculators-incometax-jsx" */),
  "component---src-pages-calculators-index-jsx": () => import("./../src/pages/calculators/index.jsx" /* webpackChunkName: "component---src-pages-calculators-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-support-jsx": () => import("./../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-user-agreement-jsx": () => import("./../src/pages/user-agreement.jsx" /* webpackChunkName: "component---src-pages-user-agreement-jsx" */),
  "component---src-templates-blog-details-jsx": () => import("./../src/templates/blog-details.jsx" /* webpackChunkName: "component---src-templates-blog-details-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-press-release-details-jsx": () => import("./../src/templates/press-release-details.jsx" /* webpackChunkName: "component---src-templates-press-release-details-jsx" */),
  "component---src-templates-press-releases-jsx": () => import("./../src/templates/press-releases.jsx" /* webpackChunkName: "component---src-templates-press-releases-jsx" */)
}

